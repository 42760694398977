<template>
  <div>
    <s-crud
      title="Datos Almacén"
      :filter="filter"
      height="450"
      :config="configWarehouseEntrance"
      @rowSelected="rowSelected($event)"
      add
      remove
      edit
    >
      <template slot-scope="props">
        <v-col>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form" lg="10">
              <s-text label="Nombre de almacén" v-model="props.item.WreName" />
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="4" class="s-col-form" md="12" lg="3">
              <v-switch
                v-model="props.item.Cost"
                color="primary"
                label="Costeable"
              ></v-switch>
            </v-col>
            <v-col cols="4" class="s-col-form" lg="4" md="8">
              <s-select-definition
                :def="1350"
                v-model="props.item.TypeWarehouse"
                label="Tipo Bodega"
              />
            </v-col>
            <v-col cols="4" class="s-col-form" lg="3" md="4">
              <v-switch
                v-model="props.item.WreStatus"
                color="primary"
                label="Inactivo"
              ></v-switch>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </s-crud>

    <v-btn color="info" @click="ghost">Subir SAP</v-btn>
  </div>
</template>

<script>
import _SWarehouseService from "@/services/Technicalassistance/WarehouseService";
import _sLayetteService from "@/services/FreshProduction/CreateLayetteService";
import _sAlbaranService from "@/services/FreshProduction/PrfAlbaranService";

export default {
  components: {},
  props: {},
  data: () => ({
    configWarehouseEntrance: {
      model: {
        WrhID: "ID",
        ArticleName: "string",
        WrhStock: "string",
        SecStatus: "int",
      },
      service: _SWarehouseService,
      headers: [
        { text: "ID", value: "WrhID" },
        { text: "Artículo", value: "ArticleName" },
        { text: "Stock", value: "WrhStock" },
      ],
    },
  }),
  methods: {
    Initialize() {
      this.filter = {};
    },
    rowSelected(rows) {
      if (rows.length > 0) {
        this.itemSelected = rows;
        this.$emit("rowSelected", rows);
      }
    },

    save(item) {
      if (item.WreNumberRequirement == "") {
        this.$fun.alert("Ingrese número de requerimiento", "error");
        return;
      }
      if (item.WreReason == "") {
        this.$fun.alert("Ingrese razón", "error");
        return;
      }
      console.log("item", item);
      item.SecStatus = 1;
      item.save();
    },

    verifySuppliesNotRecommended() {},

    ghost() {
      /* _sAlbaranService.loadsapalbaran(1, this.$fun.getUserID())
        .then((resp) => {
            if(resp.status == 200){
                alert();
            }
        }); */

      const item = {
        LayID: 1003,
        TnkID: 1,
        LayDateBegin: "2021-11-19 15:51:12.777",
        LayDateEnd: "2021-11-22 09:56:12.777",
        LayObservation: "",
        LayStatus: 2,
        TypeCoolingStatus: 1,
        SecStatus: 1,
        UsrCreateID: 42,
        UsrUpdateID: 42,
        SecCreate: "2021-11-19 15:51:12.777",
        SecUpdate: "2021-11-19 15:51:12.777",
      };
      _sLayetteService
        .save(item, this.$fun.getUserID())

        .then((resp) => {
          if (resp.status == 200) {
            console.log("cambiado correctamente");
            _sLayetteService.list(this.$fun.getUserID()).then((resp) => {
              if (resp.status == 200) {
                let data = resp.data;
                this.listLayette = data;
              }
            });
          }
        });
    },
  },

  watch: {},
  created() {
    this.filter = {};
    //verifySuppliesNotRecommended();
  },
};
</script>
